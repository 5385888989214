import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
// routes
import routes, { renderRoutes } from "./routes";
// redux
import { store, persistor } from "./redux/store";
// theme
import ThemeConfig from "./theme";
// components
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen from "./components/LoadingScreen";

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeConfig>
            <RtlLayout>
              <Router history={history}>
                <ScrollToTop />
                {renderRoutes(routes)}
              </Router>
            </RtlLayout>
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
